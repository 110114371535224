import React, { createContext, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import axios, { AxiosInstance } from 'axios';

import { useAppInsight } from 'hooks/useAppInsight';
import { useBuildVersionQuery } from 'hooks/useBuildVersionQuery';
import { useConfiguration } from 'hooks/useConfiguration';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { buildVersionAtom } from 'models/BuildVersionModel';

interface Props {
  children: React.ReactNode;
}

export const AxiosContext = createContext(null as unknown as AxiosInstance);
export const AxiosProvider: React.FC<Props> = ({ children }) => {
  const {
    apiUrl,
    apiFuncUrl,
    appInsightOptions: { enabled: appInsightEnabled },
  } = useConfiguration();
  const { apiFuncIntegration } = useFeatureFlags();
  const { appInsights } = useAppInsight();
  const baseUrl = apiFuncIntegration ? apiFuncUrl : apiUrl;
  const instanceRef = useRef(axios.create({ baseURL: baseUrl }));
  const { getAccessTokenSilently, logout } = useAuth0();
  const { mutateAsync: getBuildVersion } = useBuildVersionQuery();
  const previousBuildVersion = useRecoilValue(buildVersionAtom);

  useEffect(() => {
    instanceRef.current = axios.create({ baseURL: baseUrl });
  }, [baseUrl]);

  useEffect(() => {
    if (!previousBuildVersion) {
      return;
    }
    instanceRef.current.interceptors.request.use(
      async (config) => {
        const token = await getAccessTokenSilently();

        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        config.params = { ...config.params, buildVersion: previousBuildVersion };

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instanceRef.current.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error?.response?.status === 403 || error?.response?.status === 401) {
          logout({ returnTo: window.location.origin });
        }

        if (error?.response?.status === 404 || error?.response?.status === 500) {
          if (appInsightEnabled) {
            appInsights.trackException(error);
          }
        }

        try {
          const { version: currentBuildVersion } = await getBuildVersion();

          if (currentBuildVersion !== previousBuildVersion) {
            window.location.reload();
          }
        } catch (exception: any) {
          appInsights.trackException(exception);

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  }, [previousBuildVersion]);

  return <AxiosContext.Provider value={instanceRef.current}>{children}</AxiosContext.Provider>;
};
